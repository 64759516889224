<template>
  <v-card outlined>
    <v-card-title class="text-h5" style="font-weight: bold">COLORS</v-card-title>
    <v-divider class="my-auto py-0"></v-divider>
    <!-- <v-row > -->
    <!-- <v-col md="4" class="mx-4">
        <v-btn class="my-4" block @click="color = null">null</v-btn>
        <v-btn class="my-4" block @click="color = '#ff00ff'">hex</v-btn>
        <v-btn class="my-4" block @click="color = '#ff00ffff'">hexa</v-btn>
        <v-btn class="my-4" block @click="color = { r: 255, g: 0, b: 255, a: 1 }">rgba</v-btn>
        <v-btn class="my-4" block @click="color = { h: 300, s: 1, l: 0.5, a: 1 }">hsla</v-btn>
        <v-btn class="my-4" block @click="color = { h: 300, s: 1, v: 1, a: 1 }">hsva</v-btn>
      </v-col> -->
    <!-- <div> -->
    <v-col align="center">
      <v-color-picker
        width="500"
        swatches-max-width="300px"
        elevation="15"
        :swatches="swatches"
        show-swatches
        v-model="color"
      ></v-color-picker>
    </v-col>
    <!-- </div> -->
    <!-- <v-col md="3" class="px-0">
        <v-sheet class="pa-4">
          <pre>{{ color }}</pre>
        </v-sheet>
      </v-col> -->
    <!-- </v-row> -->
  </v-card>
</template>

<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  watch: {
    value() {
      this.color = this.value;
    },
    color() {
      this.$emit("input", this.color);
    },
  },
  data: () => ({
    color: null,
    swatches: [
      ["#FF0000", "#AA0000", "#550000", "#000000"],
      ["#FFFF00", "#AAAA00", "#555500", "#0C090A"],
      ["#00FF00", "#00AA00", "#005500", "#A9A9A9"],
      ["#00FFFF", "#00AAAA", "#005555", "#C0C0C0"],
      ["#0000FF", "#0000AA", "#000055", "#C0C0C0"],
    ],
  }),
  mounted() {
    this.color = this.value;
  },
};
</script>
