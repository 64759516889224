<template>
  <v-sheet class="" id="employee">
    <!-- <pre>{{ employee }}</pre> -->
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">{{ title }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
             
        <v-btn
              class="mr-2"
              v-if="stepperHeader.value > 1"
              :disabled="pageLoading"
              depressed
              tile
              v-on:click="stepperHeader.value--"
            >
              Previous
            </v-btn>
            <v-btn 
              :disabled="pageLoading || !firstStepValid"
              :loading="pageLoading"
              class="white--text mx-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="saveEmployee"
            >
              {{ stepperHeader.value === stepperHeader.header?.length ? "Save" : "Next" }}
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Cancel
            </v-btn>
      
      </v-col>
    </v-row>

    <v-stepper v-model="stepperHeader.value">
      <v-stepper-header>
        <v-stepper-step
          color="#0D47A1"
          v-for="(s_header, index) in stepperHeader.stepper_h"
          :key="`_stepper_contact_person${index + 1}_${s_header.title}`"
          :complete="stepperHeader.value > index + 1"
          :step="index + 1"
        >
          {{ s_header.title }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="stepper_content">
          <v-form
                  ref="firstStep"
                  v-model="firstStepValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateOrCreate"
                >
          <v-row>
            <v-col md="8" class="my-auto py-0">
              <v-row>
                <v-col md="12" class="">
                  <v-card outlined>
                    <v-card-title class="text-h5" style="font-weight: bold"
                      >BASIC DETAILS</v-card-title
                    >
                    <v-divider class="my-auto py-0"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col md="2" class="my-auto py-0">
                          <label for="primary-contact" class="btx-label mt-2 required">Name </label>
                        </v-col>
                        <v-col md="10" class="py-0">
                          <v-layout>
                            <v-flex class="max-width-100px">
                              <SelectInput
                                hide-details
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                :items.sync="listTitle"
                                v-model="employee.title"
                                id="salutation"
                                placeholder="Title"
                              ></SelectInput>
                            </v-flex>
                            <v-flex class="mx-2">
                              <TextInput
                                hide-details
                                hint
                                counter="10"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                id="first-name"
                                
                                v-model="employee.first_name"
                               
                                :rules="[vrules.required(employee.first_name, 'First Name'),vrules.maxLength(employee.first_name, 'First Name',25),vrules.minLength(employee.first_name, 'First Name',3)]"
                                :class="{ required: !employee.first_name }"
                                placeholder="First Name"
                              ></TextInput>
                              <span v-if="firstname_status==1" style="color:red;">First Name Already Exits</span>
                            </v-flex>
                            <v-flex>
                              <TextInput
                                hide-details
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                id="last-name"
                                placeholder="Last Name"
                                :rules="[vrules.maxLength(employee.last_name, 'Last Name',25),vrules.minLength(employee.last_name, 'Last Name',3)]"

                                v-model="employee.last_name"
                              ></TextInput>
                            </v-flex>
                          </v-layout>
                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="email" class="btx-label mt-2 required">Display Name</label>
                        </v-col>
                        <v-col md="10" class="py-0">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="name"
                            placeholder="Display Name"
                            v-model="employee.display_name"
                            :rules="[vrules.required(employee.display_name, 'Display Name'),vrules.maxLength(employee.display_name, 'Display Name',25),vrules.minLength(employee.display_name, 'Display Name',3)]"
                            :class="{ required: !employee.display_name }"
                          ></TextInput>
                          <span v-if="display_name_status==1" style="color:red;">Display Name Already Exits</span>

                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="email" class="btx-label mt-2 required">Email </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <EmailInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="email"
                            placeholder="Email"
                            v-model="employee.employee_email"
                            :rules="[vrules.required(employee.employee_email, 'Employee Email'),vrules.maxLength(employee.employee_email, 'Employee Email',25),vrules.minLength(employee.employee_email, 'Employee Email',5)]"
                            :class="{ required: !employee.employee_email }"
                          ></EmailInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="phone-number" class="btx-label mt-2 text-right required"
                            >Phone Number</label
                          >
                        </v-col>
                        <v-col md="4" class="py-0 mt-2">
                          <!-- <PhoneTemplate
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="phone-number"
                            item-text="number"
                            item-value="id"
                            placeholder="Phone Number"
                            v-model="employee.phone_number"
                          ></PhoneTemplate> -->
                          <PhoneTextField
                            required
                            v-model="employee.phone_number"
                      label="Mobile Number" hide-details placeholder="Phone"
                          ></PhoneTextField>
                        </v-col>

                        <v-col md="2" class="my-auto py-0">
                          <label  class="btx-label mt-2">Designation </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <AutoCompleteInput
                          class="mt-2"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="RoleList"
                            id="Department"
                            item-text="text"
                            item-value="value"
                            placeholder="Select Role"
                            v-model="employee.role_name"
                          ></AutoCompleteInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="phone-number" class="btx-label mt-2 text-right required"
                            >Staff Department</label
                          >
                        </v-col>
                        <v-col md="4" class="py-0">
                          <AutoCompleteInput
                          class="mt-2"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="department"
                            id="Department"
                            item-text="text"
                            item-value="value"

                            placeholder="Select Department"
                            v-model="employee.department"
                            :rules="[vrules.required(employee.department, 'Department')]"
                            :class="{ required: !employee.department }"
                          ></AutoCompleteInput>
                        </v-col>

                        <!-- <v-col md="1" class="my-auto py-0">
                          <v-checkbox></v-checkbox>
                        </v-col> -->
                        <v-col md="2" class="text-left my-auto py-0">
                          <label  class="btx-label mt-2">Washing Machine </label>
                        </v-col>
                        <!-- <v-col md="1" class="py-0">
                          <v-checkbox class="pa-0"></v-checkbox>
                        </v-col> -->
                        <v-col md="4" class="text-right py-0">
                          <v-flex class="d-flex">
                            <v-checkbox class="pa-0" v-model="employee.machine_checkbox"
 color="#0D47A1"></v-checkbox>
                            <TextInput
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              v-model="employee.machine"
                              placeholder="Washing Machine"
                            ></TextInput>
                          </v-flex>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="12">
                  <v-card outlined>
                    <v-card-title class="text-h5" style="font-weight: bold"
                      >ID DETAILS</v-card-title
                    >
                    <v-divider class="my-auto py-0"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col md="2" class="my-auto py-0">
                          <label for="country" class="btx-label mt-2 required">Country </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <AutoCompleteInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items.sync="countryList"
                            :rules="[vrules.required(employee.country, 'Country')]"
                            :class="{ required: !employee.country }"
                            id="probation_period"
                            item-text="text"
                            item-value="value"
                            v-model="employee.country"
                            placeholder="Select Country"
                          ></AutoCompleteInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0 text-right">
                          <label for="mykad" class="btx-label mt-2 text-right">My Kad</label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="mykad"
                            placeholder="My Kad"
                            v-model="employee.mykad"
                          ></TextInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-1">
                          <label for="email" class="btx-label mt-2 required">Passport </label>
                        </v-col>
                        <v-col md="4" class="py-1">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="passport"
                            v-model="employee.passport"
                            placeholder="Employee passport"
                            :rules="[vrules.required(employee.passport, 'Passport'),vrules.maxLength(employee.passport, 'Passport',14),vrules.minLength(employee.passport, 'Passport',8)]"
                            :class="{ required: !employee.passport }"
                          ></TextInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-1 text-right">
                          <label for="phone-number" class="btx-label mt-2 text-right">Visa</label>
                        </v-col>
                        <v-col md="4" class="py-1">
                          <TextInput
                            hide-details
                            type="number"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="visa_number"
                            v-model="employee.visa_no"
                            placeholder="Visa Number"
                            :rules="[vrules.required(employee.visa_no, 'Visa No'),vrules.maxLength(employee.visa_no, 'Visa No',16)]"
                            :class="{ required: !employee.visa_no }"
                          ></TextInput>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" class="">
              <v-card outlined height="558">
                <v-card-text>
                  <v-col
                    md="12"
                    align="center"
                    justify="center"
                    class="px-6 pb-6 d-flex align-center justify-center"
                  >
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="height: 100%; width: 100%">
                          <ImageTemplate :src="getProfileImage"></ImageTemplate>
                          <v-btn
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            class="white--text mx-2"
                            depressed
                            color="blue darken-4"
                            tile
                            style
                            v-on:click="fileInput()"
                          >
                            {{ employee.image ? "Reset Image" : "Upload Image" }}
                            <v-icon right style="font-size: 21px">
                              {{ `mdi-${employee.image ? "refresh" : "cloud-upload-outline"}` }}
                            </v-icon>
                          </v-btn>
                          <div class="mt-3">
                            <label for="product-image" class="field-label mt-0 mb-1">
                              Allowed JPG or PNG. Max size of 800K
                            </label>
                          </div>
                        </div>
                      </template>
                      <span> Upload Images </span>
                    </v-tooltip>
                  </v-col>
                  <v-col md="12" class="pa-0" v-show="false">
                    <v-row class="pa-0" style="margin: 0% !important">
                      <v-col md="2" sm="3" align="center" justify="center" class="pa-0 mt-2">
                        <v-file-input
                          id="product-image"
                          accept="image/png, image/jpeg, image/bmp"
                          ref="fileInput"
                          class="d-none"
                          v-on:change="updateProfileImage"
                        >
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-form>
        </v-stepper-content>
        <!-- style="padding: 0px 0px 0px 0px;" -->
        <v-stepper-content step="2" class="stepper_content">
          <v-form
                  ref="secondStep"
                  v-model="secondStepValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateOrCreate"
                >
          <v-row>
            <v-col md="11" class="my-auto py-0">
              <v-row>
                <v-col md="12" class="">
                  <v-card outlined>
                    <v-card-title class="text-h5" style="font-weight: bold"
                      >SALARY DETAILS</v-card-title
                    >
                    <v-divider class="my-auto py-0"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col md="2" class="my-auto py-0">
                          <label for="joining-role" class="btx-label mt-2 required"
                            >Joining Date
                          </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <DatePicker
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="joining-role"
                            placeholder="Joining Date"
                            v-model="employee.joining_date"
                            :rules="[vrules.required(employee.joining_date, 'Joining role')]"
                            :customClass="{ required: !employee.joining_date }"
                          ></DatePicker>
                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="job-type" class="btx-label mt-2 text-right "
                            >Job Role</label
                          >
                        </v-col>
                        <v-col md="4" class="py-0">
                          <SelectInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="['Manger', 'Director', 'Seo']"
                            id="job-type"
                            item-text="job-type"
                            item-value="id"
                            placeholder="Job Role"
                            v-model="employee.job_type"
                           
                          ></SelectInput>
                        </v-col>

                        <v-col md="2" class="my-auto py-0">
                          <label for="probation-period" class="btx-label mt-2 required"
                            >Probation Period
                          </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <SelectInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="probationPeriodList"
                            id="probation-period"
                            item-value="value"
                            item-text="text"
                            placeholder="Probation Period"
                            v-model="employee.probation_period"
                            :rules="[
                              vrules.required(employee.probation_period, 'Probation Period'),
                            ]"
                            :class="{ required: !employee.probation_period }"
                          ></SelectInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0"> </v-col>
                        <v-col md="4" class="py-0"></v-col>

                        <v-col md="2" class="my-auto py-0">
                          <label for="salary-during-probation" class="btx-label mt-2 required"
                            >Salary During Probation
                          </label>
                        </v-col>
                        <v-col md="2" class="py-0">
                          <NumberInput
                            hide-details
                            mask="###########"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="salary-during-probation"
                            v-model="employee.during_probs_salary"
                            placeholder="Salary During Probation"
                            :rules="[
                              vrules.required(
                                employee.during_probs_salary,
                                'During Salary Probation'
                              ),
                            ]"
                            :class="{ required: !employee.during_probs_salary }"
                          ></NumberInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <DatePicker
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="date"
                            placeholder="Date"
                            v-model="employee.during_probs_date"
                            :rules="[
                              vrules.required(
                                employee.during_probs_date,
                                'During Salary Probation'
                              ),]"
                            :customClass="{ required: !employee.during_probs_date }"

                          ></DatePicker>
                        </v-col>

                        <v-col md="2" class="my-auto py-0"> </v-col>
                        <v-col md="4" class="py-0"> </v-col>

                        <v-col md="2" class="my-auto py-0">
                          <label for="salary-After-probation" class="btx-label mt-2 required"
                            >Salary After Probation
                          </label>
                        </v-col>
                        <v-col md="2" class="py-0">
                          <NumberInput
                          mask="###########"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="salary-after-probation"
                            v-model="employee.after_probs_salary"
                            placeholder="Salary After Probation"
                            :rules="[
                              vrules.required(
                                employee.after_probs_salary,
                                'Salary After Probation'
                              ),
                            ]"
                            :class="{ required: !employee.after_probs_salary }"
                          ></NumberInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <DatePicker
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="date"
                            placeholder="Date"
                            :rules="[
                              vrules.required(
                                employee.after_probs_date,
                                'Salary after Probation'
                              ),]"
                            :customClass="{ required: !employee.after_probs_date }"

                            v-model="employee.after_probs_date"
                          ></DatePicker>
                        </v-col>
                        <v-col md="2" class="my-auto py-0"> </v-col>
                        <v-col md="4" class="py-0"> </v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="monthly-salary-date" class="btx-label mt-2 "
                            >Monthly Salary Date
                          </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <DatePicker
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="monthly-salary-date"
                            placeholder="Monthly Salary Date"
                            v-model="employee.monthly_salary_date"
                           
                          ></DatePicker>
                        </v-col>
                        <v-col md="2" class="my-auto py-0"></v-col>
                        <v-col md="4" class="py-0"></v-col>
                        <v-col md="2" class="my-auto py-0">
                          <label for="salary-mode" class="btx-label mt-2">Salary Mode </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <SelectInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="['BANK', 'CASH']"
                            id="salary-mode"
                            item-text="salary-mode"
                            item-value="id"
                            placeholder="Salary Mode"
                            v-model="employee.salary_mode"
                          ></SelectInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0"></v-col>
                        <v-col md="4" class="py-0"></v-col>
                        <v-col v-if="employee.salary_mode === 'BANK'" md="2" class="my-auto py-0">
                          <label for="bank-name" class="btx-label mt-2 ">BANK NAME </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <TextInput
                            v-if="employee.salary_mode === 'BANK'"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="bank-name"
                            placeholder="Bank Name"
                            v-model="employee.bank_name"
                           
                          ></TextInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0"></v-col>
                        <v-col md="4" class="py-0"></v-col>
                        <v-col v-if="employee.salary_mode === 'BANK'" md="2" class="my-auto py-0">
                          <label for="bank-account-no" class="btx-label mt-2 "
                            >BANK ACCOUNT NO.
                          </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <TextInput
                            v-if="employee.salary_mode === 'BANK'"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="bank-account-no"
                            placeholder="Bank Account No."
                            v-model="employee.bank_account_no"
                          
                          ></TextInput>
                        </v-col>
                        <v-col md="2" class="my-auto py-0"></v-col>
                        <v-col md="4" class="py-0"></v-col>
                        <v-col v-if="employee.salary_mode === 'BANK'" md="2" class="my-auto py-0">
                          <label for="bank-ifsc-code" class="btx-label mt-2 "
                            >BANK IFSC CODE
                          </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <TextInput
                            v-if="employee.salary_mode === 'BANK'"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="bank-ifsc-code"
                            placeholder="Bank Ifsc Code"
                            v-model="employee.bank_ifsc_code"
                           
                          ></TextInput>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="3" class="stepper_content">
          
          <v-row>
            <v-col md="11" class="my-auto py-2">
              {{employee.color}}
             
              <ColorPicker    v-on:input="colors"></ColorPicker>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-sheet>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import NumberInput from "@/view/components/NumberInput";
import { QUERY } from "@/core/services/store/request.module";
import EmailInput from "@/view/components/EmailInput";

// import PhoneTemplate from "@/view/components/Phone";
// import PhoneInput from "@/view/components/PhoneInput";
import PhoneTextField from "@/view/components/PhoneTextField";

import ImageTemplate from "@/view/components/Image";
import SelectInput from "@/view/components/SelectInput";
import DatePicker from "@/view/components/DatePicker";
import ColorPicker from "@/view/components/ColorPicker";
import { GET_EMPLOYEE } from "@/core/lib/employee.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import FileManagerMixin from "@/core/mixins/file-manager/file.manager.mixin";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import { head, isEmpty } from "lodash";

import { SET_ERROR,SET_MESSAGE } from "@/core/services/store/common.module";
import { SAVE_EMPLOYEE } from "@/core/lib/employee.lib";
import { mapGetters } from "vuex";
export default {
  mixins: [FileManagerMixin],
  name: "employee-create",
  title: "Create Employee",
  data() {
    return {
      e1: 1,
      categorys: [],
      color:null,
      firstname_status:0,
      display_name_status:0,
      firstStepValid: true,
      secondStepValid: true,
      thirdStepValid: true,
   
      RoleList: [
        {
          text: "WAITER",
          value: "waiter",
        },
        {
          text: "BAR",
          value: "bar",
        },
        {
          text: "CHINESE COOK",
          value: "chinese cook",
        }, 
        {
          text: "WESTERN COOK",
          value: "western cook",
        },
        {
          text: "CHINE WEST COOK",
          value: "chine west cook",
        },
        {
          text: "INDIAN COOK",
          value: "indian cook",
        },
        {
          text: "DISHWASHER",
          value: "dishwasher",
        },
        {
          text: "HELPER",
          value: "helper",
        },
        {
          text: "ADMIN",
          value: "admin",
        },
        {
          text: "MANAGER",
          value: "manager",
        },
        {
          text: "WAITERESS",
          value: "waiteress",
        },
        {
          text: "FLOOR MANAGER",
          value: "floor manager",
        },
      ],
      department: [
        {
          text: "FLOOR",
          value: "floor",
        },
        {
          text: "KITCHEN",
          value: "kitchen",
        },
        {
          text: "OFFICE",
          value: "office",
        },
      ],
      firstStep:1,
      secondStep:2,
      thirdStep:3,
      stepperHeader: {
        value: 1,
        stepper_h: [{ title: "BASIC DETAILS" }, { title: "SALARY DETAILS" }, { title: "COLOR" }],
      },
      pageLoading: false,
      positionList: [
        {
          text: "Chef",
          value: "chef",
        },
        {
          text: "Assistant Chef",
          value: "assistant_chef",
        },
        {
          text: "Kitchen Helper",
          value: "kitchen_helper",
        },
        {
          text: "Bartender",
          value: "bartender",
        },
        {
          text: "waitresses",
          value: "waitresses",
        },
      ],
      probationPeriodList: [
        {
          text: "3 MONTH",
          value: "3 month",
        },
        {
          text: "6 MONTH",
          value: "6 month",
        },
      ],
      countryList: [
     
        {
          text: "MALAYSIA",
          value: "malaysia",
        },
        {
          text: "SINGAPORE",
          value: "singapore",
        },
        {
          text: "MAYAMAR",
          value: "mayamar",
        },
        {
          text: "INDIA",
          value: "india",
        },
        {
          text: "PHILIPPINES",
          value: "philippines",
        },
        {
          text: "INDONESIA",
          value: "indonesia",
        }
      ],
      jopTypeList: [
        {
          text: "Part Time",
          value: "part_time",
        },
        {
          text: "Full Time",
          value: "full_time",
        },
      ],
      header_image_url: null,
      generateSegmentDialog: false,
      testPhoneNumber: null,
      formValid: true,
      employeeId: null,
      isBlastBroadcast: false,
      employee: {
        title: null,
        first_name: null,
        last_name: "",
        display_name: "",
        employee_email: "",
        machine_checkbox:1,
        phone_number: "",
        role_name: "",
        department: "",
        country: "",
        mykad: "",
        passport: "",
        visa_no: "",
        joining_date: "",
        job_type: "",
        probation_period: "",
        during_probs_salary: "",
        during_probs_date: "",
        after_probs_salary: "",
        after_probs_date: "",
        monthly_salary_date: "",
        salary_mode: "",
        bank_name: "",
        machine: "",
        bank_account_no: "",
        bank_ifsc_code: "",
        profile_logo: null,
        color:null
      },
    };
  },
  methods: {
    checkfirstname(e){
      if(e){
        this.$store
        .dispatch(QUERY, {
          url: "check-first_employee",
          data:{first_name : e}
        })
        .then((data) => {
             this.firstname_status = data;
          console.log(data, "dfdsf");
        })
        .catch((error) => {
          console.log(error, "error");

          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });

      }
      
    
      

    },
    checkdisplayname(e){
      if(e){
        this.$store
        .dispatch(QUERY, {
          url: "check-display_employee",
          data:{display_name : e}
        })
        .then((data) => {
             this.display_name_status = data;
          console.log(data, "dfdsf");
        })
        .catch((error) => {
          console.log(error, "error");

          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });

      }
      
    
      

    },
    checkemail(e){
      if(e){
        this.$store
        .dispatch(QUERY, {
          url: "check-email_employee",
          data:{email : e}
        })
        .then((data) => {
             this.email_status = data;
          console.log(data, "dfdsf");
        })
        .catch((error) => {
          console.log(error, "error");

          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });

      }
      
    
      

    },

    colors(param){
      this.employee.color = param.hex;
    },
   
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "data",
        })
        .then((data) => {
          this.categorys = data.departments;
          console.log(this.categorys, "dfdsf");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      let fileExtention = param?.type.split("/");
        fileExtention = fileExtention[0];
        if (fileExtention != "image") {
          _this.$store.commit(SET_ERROR, [
            { model: true, message: "Only Image required" },
          ]);
          return false;
        }
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.employee.profile_logo = head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    async getEmployeeData() {
      const _this = this;
      _this.employee_id = this.$route.params.id;
      if (_this.employee_id) {
        try {
          const employee = await GET_EMPLOYEE(_this.employee_id);
          // _this.getOrders();
          _this.employee = employee;
          // console.log(_this.employee)

          _this.$store.dispatch(SET_BREADCRUMB, [
            { text: "Employee", disabled: true },
            { text: "Detail", disabled: true },
            { text: employee.barcode, disabled: true },
          ]);
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
        }
      }
    },
    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
    handleFilesUpload() {},
    
    async saveEmployee() {

      if (this.stepperHeader.value == 1 && this.$refs.firstStep.validate()) {
        this.stepperHeader.value++;
      } else if (this.stepperHeader.value == 2 && this.$refs.secondStep.validate()) {
        this.stepperHeader.value++;
      }else if (this.stepperHeader.value == 3 && this.employee.color) {
        this.pageLoading = false;
      const _this = this;
      let body = {
        id: this.$route.name === "create-employee" ? null : this.employee_id,
        title: _this.employee.title,
        first_name: _this.employee.first_name,
        last_name: _this.employee.last_name,
        display_name: _this.employee.display_name,
        employee_email: _this.employee.employee_email,
        machine_checkbox: _this.employee.machine_checkbox,
        phone_number: _this.employee.phone_number,
        role_name: _this.employee.role_name,
        department: _this.employee.department,
        country: _this.employee.country,
        mykad: _this.employee.mykad,
        passport: _this.employee.passport,
        visa_no: _this.employee.visa_no,
        joining_date: _this.employee.joining_date,
        job_type: _this.employee.job_type,
        probation_period: _this.employee.probation_period,
        during_probs_salary: _this.employee.during_probs_salary,
        during_probs_date: _this.employee.during_probs_date,
        after_probs_salary: _this.employee.after_probs_salary,
        after_probs_date: _this.employee.after_probs_date,
        monthly_salary_date: _this.employee.monthly_salary_date,
        salary_mode: _this.employee.salary_mode,
        machine: _this.employee.machine,
        bank_name: _this.employee.bank_name,
        bank_account_no: _this.employee.bank_account_no,
        bank_ifsc_code: _this.employee.bank_ifsc_code,
        image: _this.employee?.profile_logo?.id,
        color: _this.employee.color,

      
      };
      console.log(body,'body')
      try {
        const { id } = await SAVE_EMPLOYEE(body);
        this.broadcast_id = id;
        console.log(this.broadcast_id, "id====>");
       
          if (this.$route.name == "update-employee") {
            _this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Employee Updated Successfully." },
            ]);
          } else {
            _this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Employee Created Successfully." },
            ]);
          }
        
        this.$router.push({
          name: "employee",
          query: { t: new Date().getTime() },
        });
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }

      }
  
    },
  },
  computed: {
    ...mapGetters(["errors"]),
    title() {
      if (this.$route.name == "update-employee") {
        return "Update Employee";
      } else {
        return "Create Employee";
      }
    },
    getProfileImage() {
      return isEmpty(this.employee.profile_logo) === false
        ? this.employee.profile_logo.file.url
        : process.env.VUE_APP_BASE_URL + "media/misc/no_photo_found.png";
    },
  },
  mounted() {
     console.log(this.$route.params.id)
    const _this = this;
    _this.getData();

    _this.getEmployeeData();
  },

  components: {
    TextInput,
    SelectInput,
    ImageTemplate,
    ColorPicker,
    // TextAreaInput,
    DatePicker,
    NumberInput,
    EmailInput,
    // PhoneTemplate,
    PhoneTextField,
    AutoCompleteInput
  },

  //   async mounted() {
  //     if (this.$route.name == "update-broadcast") {
  //       await this.getBroadcast();
  //     }
  //     if (this.$route.name == "create-broadcast") {
  //       this.broadcast.selectedTemplate = 0;
  //       await this.filterTemplateList();
  //     }

  //     if (this.$route.name == "update-broadcast") {
  //       this.broadcast_id = this.$route.params.id;
  //     }
  //   },
};
</script>

<style>
.v-image.v-responsive.white.lighten-2.theme--light {
  height: 272px;
}
.stepper_content {
  padding: 2px 24px 16px 24px;
}
</style>
